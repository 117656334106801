/* MODAL STYLES */

.modal-error .modal-dialog {
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: 25px;
  padding: 0;
  border: #d8000c;
  border-style: double;
  border-radius: 2mm;
}

.modal-stateStore .modal-dialog {
  width: 90%;
  max-width: 700px;
  margin: auto;
  margin-top: 25px;
  padding: 0;
}

.modal-edit .modal-dialog {
  width: 90%;
  max-width: 700px;
  height: 90%;
  max-height: calc(90%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}

.modal-800 .modal-dialog {
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: calc(90%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}

.modal-search .modal-dialog {
  width: 95%;
  max-width: 1200px;
  max-height: calc(95%);
  margin: auto;
  margin-top: 15px;
  padding: 0;
}

.modal-1200 .modal-dialog {
  width: 95%;
  max-width: 1200px;
  height: 95%;
  max-height: calc(95%);
  margin: auto;
  margin-top: 20px;
  padding: 0;
}

.modal-1400 .modal-dialog {
  width: 90%;
  max-width: 1400px;
  max-height: 95%;
  margin: auto;
  padding: 0;
  margin-top: 20px;
}

.modal-1500 .modal-dialog {
  width: 98%;
  max-width: 1500px;
  max-height: 95%;
  margin: auto;
  padding: 0;
  margin-top: 3%;
}

.modal-help .modal-dialog {
  width: 80%;
  max-width: 500;
  margin: auto;
  padding: 0;
  position: relative;
  top: 10%;
  bottom: 15%;
  transform: translateY(-50%);
}
// .modal-help .modal-body {
//   height: 550px;
// }

.modal-1000 .modal-dialog {
  width: 95%;
  max-width: 1000px;
  height: 95%;
  max-height: calc(95%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}

.modal-infopdf .modal-dialog {
  width: 99%;
  max-width: calc(99%);
  height: 99%;
  max-height: calc(99%);
  margin: auto;
  padding: 0;
}

