@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "./variables.scss";
@import "./material.scss";
@import "./modals.scss";
@import "./helpers.scss";

/*****************************************    HACKS    **************************************************/
/* Required for 1st modal to be in focus after returning from second, stacked modal */
.modal {
  overflow: auto !important;
}
/* Required for toast notifications to be visible (name collision with toast and bootstrap) */
#toast-container > div {
  opacity: 1;
}
/********************************************************************************************************/

.page {
  padding: 5px 5px 0 5px;
}

.modal-header {
  justify-content: space-between;
}

/* minus menu height  */
.grid-height {
  max-height: calc(100vh - 60px);
  min-height: 200px;
}

.maintenancegrid-height {
  max-height: calc(100vh - 100px);
  min-height: 310px;
}

.taskgrid-height {
  max-height: calc(100vh - 250px);
  min-height: 300px;
}

.btn-outline-primary {
  color: rgb(0, 76, 255);
  background: white;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.btn-outline-primary:hover {
  background: rgb(0, 76, 255);
  color: white;
}

.red {
  color: red;
}

.red-bold {
  color: red;
  font-weight: bold;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red:hover {
  color: white;
  background: red;
}

.green:hover {
  color: white;
  background: green;
}

.redNote {
  color: red;
}

.greenNote {
  color: green;
}

.redNote:hover {
  color: red;
}

.greenNote:hover {
  color: green;
}

.strikeclass {
  text-decoration: line-through;
}

.boldText {
  font-weight: bold;
}

.wrapText {
  white-space: break-spaces;
  text-align: left;
  max-width: 100%; /* needed to make the wrapping of full width words work */
}

.error {
  margin: 20px 100px;
  text-align: center;
  padding: 12px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(253, 175, 175);
  border-width: 2px;
  color: #d8000c;
  background-color: #ffd2d2;
}
.error p {
  font-size: 1em;
  vertical-align: middle;
  display: inline;
}

.error-small {
  margin: 0px 10px;
  padding: 3px;
  text-align: center;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(253, 175, 175);
  border-width: 2px;
  color: #d8000c;
  background-color: #ffd2d2;
}
.error-small p {
  font-size: 1em;
  vertical-align: middle;
  display: inline;
}

.success {
  margin: 20px 100px;
  text-align: center;
  padding: 6px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(213, 240, 166);
  border-width: 2px;
  color: #4f8a10;
  background-color: #dff2bf;
}
.success h4 {
  vertical-align: middle;
  display: inline;
}

.globalTreeclass {
  height: 250px;
  font-size: 0.8em;
  width: 100%;
  border: 1mm;
  border-style: ridge;
  overflow: hidden;
  margin: 15px, 15px, 15px, 15px;
}

.jobClass {
  display: inline-flex;
}

.jobString {
  display: inline-block;
  max-height: 36px;
  margin-left: 8px;
}

.jobStringText {
  overflow: hidden;
}

.w100 {
    width: 100px;
}

.inlineFlex {
  display: inline-flex;
}

.text-right {
  text-align: end;
}

.floatRight{
  float: right;
}

.backdropClass {
  background-color: transparent;
}
